<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="formLoader">
                    <b-row>
                       <b-col xl="12" lg="12" sm="12">
                          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                          <b-form  @submit.prevent="handleSubmit(rejectFundRequest)" @reset.prevent="reset">
                              <ValidationProvider name="Reject Note" vid="reject_note" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="3"
                                    label-for="reject_note"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                      {{ $t('research_manage.reject_note') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-textarea
                                    id="reject_note"
                                    v-model="reject.reject_note"
                                    class="mb-3"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                   ></b-form-textarea>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            <div class="row">
                              <div class="col text-right">
                                  <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                  &nbsp;
                                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-2')">{{ $t('globalTrans.cancel') }}</b-button>
                              </div>
                            </div>
                          </b-form>
                          </ValidationObserver>
                      </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { fundRequestStatusChangeApi } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
  },
  mounted () {
  },
  data () {
    return {
      formLoader: false,
      reject: {
        id: this.id,
        reject_note: '',
        status: 3
      },
      saveBtnName: this.$t('globalTrans.update')
    }
  },
  computed: {
  },
  methods: {
    async rejectFundRequest () {
      this.formLoader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })

      let result = null

      result = await RestApi.postData(agriResearchServiceBaseUrl, fundRequestStatusChangeApi, this.reject)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        // const notification = result.notification
        // this.$socket.emit('send-notification', notification)
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.update_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-2')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      }

      this.formLoader = false
    }
  }
}
</script>
