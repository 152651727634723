<template>
  <b-container fluid>

    <!-- Search Form -->
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('external_research.fund_request') + ' ' + $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="searchLoader">
          <b-row>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('research_manage.thematic_area')"
                label-for="thematic_area_id"
                >
                <b-form-select
                    plain
                    v-model="search.thematic_area_id"
                    :options="thematicAreaList"
                    id="thematic_area_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
            </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  :label="$t('external_research.project_title')"
                  label-for="project_title"
                  >
                  <b-form-select
                    plain
                    v-model="search.project_title"
                    id="project_title"
                    :options="projectList"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                  </b-form-group>
              </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('research_manage.project_id')"
                label-for="project_status"
                >
                <b-form-input
                    plain
                    v-model="search.project_id"
                    id="project_status"
                    >
                </b-form-input>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
    </iq-card>
    <!-- /Search Form -->

    <!-- List-Table -->
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('external_research.fund_request') + ' ' + $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col md="12" class="table-responsive">
              <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>

                <template v-slot:cell(index)="data">
                  {{ $n(data.index + pagination.slOffset) }}
                </template>

                <template v-slot:cell(action)="data">
                  <b-button v-if="data.item.status==1" size="sm" variant=" iq-bg-success mr-1" title="Approve" @click="changeStatus(data.item.id, 2)">
                      <i class="ri-check-line m-0"></i>
                  </b-button>
                  <b-button v-if="data.item.status==1" v-b-modal.modal-2 size="sm" variant=" iq-bg-danger mr-1" title="Reject" @click="reject(data.item)">
                    <i class="ri-close-line m-0"></i>
                  </b-button>
                  <b-button v-b-modal.modal-1 variant=" iq-bg-success" size="sm" title="View" @click="details(data.item)">
                    <i class="ri-eye-line m-0"></i>
                  </b-button>
                </template>
              </b-table>
              <b-pagination
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                @input="searchData"
              />
            </b-col>
          </b-row>
        </b-overlay>
      </template>
    </iq-card>
    <!-- /List-Table -->

    <!-- Details -->
    <b-modal id="modal-1" size="lg" :title="this.$t('external_research.fund_request') + ' ' + this.$t('globalTrans.details')" ok-only ok-variant="danger">
      <Details :id="viewItemId" :key="viewItemId"/>
    </b-modal>

    <!-- Form -->
    <b-modal id="modal-2" size="lg" :title="formTitle" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>

  </b-container>
</template>
<script>

import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { fundRequestListApi, fundRequestStatusChangeApi, projectListApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './Details'
import Form from './Form'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details,
    Form
  },
  data () {
    return {
      searchLoader: false,
      rows: [],
      item: '',
      projectList: [],
      search: {
        project_title: 0,
        project_id: '',
        thematic_area_id: 0
      },
      viewItemId: 0,
      editItemId: 0
    }
  },
  created () {
    this.getProjectList()
    this.loadData()
  },
  computed: {
    formTitle () {
      return this.$t('external_research.fund_request') + ' ' + this.$t('globalTrans.entry')
    },
    currentLocale () {
      return this.$i18n.locale
    },
    thematicAreaList: function () {
        return this.$store.state.AgriResearch.commonObj.thematicAreaList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
       return this.$store.state.commonObj.fiscalYearList
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('external_research.project_title'), class: 'text-left' },
          { label: this.$t('external_research.proposal_id'), class: 'text-left' },
          { label: this.$t('external_research.thematic_area'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'project_title_bn' },
          { key: 'proposal_auto_id' },
          { key: 'thematic_area_name_bn' },
          { key: 'request_status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'project_title' },
          { key: 'proposal_auto_id' },
          { key: 'thematic_area_name' },
          { key: 'request_status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    async getProjectList () {
      this.searchLoader = true
      const params = { proposal_status: 2 }
      const result = await RestApi.getData(agriResearchServiceBaseUrl, projectListApi, params)

      if (result.success && result.data) {
        this.projectList = result.data.map((obj, key) => {
          return { ...obj, text: this.currentLocale === 'en' ? obj.text_en : obj.text_bn }
        })
      }

      this.searchLoader = false
    },
    searchData () {
      this.loadData()
    },
    reject (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.viewItemId = item.id
    },
    async changeStatus (id, status) {
      const confirmResult = await window.vm.$swal({
        title: this.$t('research_manage.approve_msg'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      })
      if (!confirmResult.isConfirmed) { return false }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      result = await RestApi.postData(agriResearchServiceBaseUrl, fundRequestStatusChangeApi, { id: id, status: status })
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        // const notification = result.notification
        // this.$socket.emit('send-notification', notification)
        this.$toast.success({
          title: 'Success',
          message: this.$t('globalTrans.update_msg'),
          color: '#D6E09B'
        })
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, fundRequestListApi, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelationalData(response.data.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
      //   // Relational
        const thematicArea = this.$store.state.AgriResearch.commonObj.thematicAreaList.find(el => el.value === item.thematic_area_id)
        let status = ''
        if (item.status === 1) {
          status = this.$t('globalTrans.pending')
        } else if (item.status === 2) {
          status = this.$t('globalTrans.approved')
        } else if (item.status === 3) {
          status = this.$t('globalTrans.rejected')
        } else {
          status = this.$t('globalTrans.paid')
        }
        // Evaluate
        const evaluationData = {
          project_title: item.project_title === undefined ? null : item.project_title,
          project_title_bn: item.project_title_bn === undefined ? null : item.project_title_bn,
          proposal_auto_id: item.proposal_auto_id === undefined ? null : item.proposal_auto_id,
          thematic_area_name: thematicArea === undefined ? null : thematicArea.text_en,
          thematic_area_name_bn: thematicArea === undefined ? null : thematicArea.text_bn,
          request_status: status
        }

      //   // Assign
        return Object.assign({}, item, evaluationData)
      })
      return listData
    }
  }
}
</script>
